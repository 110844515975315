@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Anek+Telugu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=GFS+Neohellenic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

.font{
  font-family: 'Montserrat', sans-serif;
}
.guide{
  font-family: "Archivo", sans-serif;
}
body{
  font-family: 'Montserrat', sans-serif;
}
body > link {
  display: none;
}

.lpbg{
  background: linear-gradient(-135deg, #158db8, transparent,transparent,transparent, transparent, #158db8);
}
.lpbgOverlay{
  background: #ffffff2e;
}

.logoFont{
  font-family: 'Antic', sans-serif;
}


.App {
  text-align: center;
  font-family: 'Anek Telugu', sans-serif;
  /* font-family: 'Merriweather', serif; */
  /* font-family: 'Antic', sans-serif; */
  font-size: large;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.video-container {
  width: 260px;
  height: 540px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: -50%;
  left: -120%;
  width: 340%;
  height: 200%;
  border-radius: 20px;
}
