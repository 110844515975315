@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic&display=swap');


body {
  margin: 0;
  font-family: 'Nanum Gothic', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:root {
  --clr: #F5F5F5;
  /* ORIGINAL COLORS */
    --second-color: #e7dfc6;
  
  /* secondaryColors */
  --main-bg-color: #102440;
}

.scrollbar2::-webkit-scrollbar {
  width: 6px;
}

.scrollbar2::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: inset 0 0 0px rgb(255, 255, 255);
}

.scrollbar2::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #686868;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.underShadow:hover{
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}


/* guide description css */
#subCatdescription{
  margin: 10px;
}
#subCatdescription h1{
  font-weight: 900;
  font-size: 35px;
  margin-top: 5px;
  margin-bottom: 1px;
}
#subCatdescription h2{
  font-weight: 700;
  font-size: 27px;
  margin-top: 5px;
  margin-bottom: 1px;
}
#subCatdescription h3{
  font-weight: 700;
  font-size: 22px;
  margin-top: 5px;
  margin-bottom: 1px;
}
#subCatdescription ol{
  list-style-type: decimal;
  list-style-position: inside;
  margin-top: 10px;
  /* margin-bottom: 10px; */
  margin-left: 15px;
}
#subCatdescription ul{
  list-style-type: disc;
  list-style-position: inside;
  margin-top: 10px;
  /* margin-bottom: 10px; */
  margin-left: 15px;
}
#subCatdescription a {
  color: blue;
  text-decoration: underline;
}
#subCatdescription .ql-indent-1{
  margin-left: 20px;
  list-style-type: circle;
}
.correctWidth{
  width: 320%!important;
}
/* DASHBOARD NAVIGATION CSS */
.dashboardNav{
  height: 90vh;

  max-width: 250px;
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: var(--main-bg-color);
}
.dashboardNav section{
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}
.dashboardNav section div{
  color: white;
  font-weight: 700;
}
.boldText{
  font-weight: 900;
}
.dashboardNav section div div{
  padding: 0.5rem;
  padding-left: 0.75rem;  
  padding-right: 0.75rem;
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.dash-sub-link{
  font-weight: 400!important;
  font-size: 15px;
}

@media screen and (max-width: 1080px) {
  .dashboardNav{
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    display: flex;
    z-index: 1000;
    max-width: 100vw;
    height: fit-content;
  }
  .dashboardNav section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: auto;
    width: 100%;
    max-width: 100%;
  }
  .dashboardNav section div {
    width: 100%;
    /* display: flex; */
    justify-content: space-around;
  }
  .dashboardNav section div div{
    text-align: center;
    width: 30%;
    padding-left: 0px;  
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  };
  .dashboardNav section p {
    padding-left: 45px;
  }
  .hiddenDNav {
    display: none;
  }
  .dNavOpen{
    opacity: 100;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    height: 170px;
    transition: .5s ease;
  }
  .dNavOpen div{
    width: 65%!important;
    border-top: #e7dfc6 2px solid;
  }
  .dNavOpen div:active{
    background-color: #046772;
  }
  .dnavClosed{
    position: relative;
    opacity: 0;
    z-index: -10;
    display: flex;
    flex-wrap: wrap;
    height: 0px;
    transition: .5s ease;
  }
  .dnavClosed div{
    display: flex;
    position: absolute;
    bottom: 0;
    opacity: 0;
    z-index: -10;
  }
  /* .dnavClosed section{

  } */
}

/* HOME PAGE CSS */

.navOpen {
  background-color: var(--main-bg-color); 
  color: white;
  font-weight: 600;
  height: 198px;
  transition: .5s ease;
}

.mainColor{
  /* background-color: #53B3CB; */
  background-color: var(--main-bg-color);
}
.secondColor{
  background-color: var(--second-color);
}

.secondText {
  color: var(--second-color)
}

.mainNav{
  color: white;
  display: flex;
}


@media screen and (max-width: 767px) {
  .mainNavOpen{
    background-color: var(--main-bg-color);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    opacity: 100;
    height: 310px;
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.175);
    margin-top: 12px;
  }
  .mainNavOpen div {
    border-top: var(--second-color) 2px solid;
    padding: 6px;
    /* padding-top: 8px; */
    width: 65%;
  }
  .mainNavOpen div:active {
    border-top: var(--second-color) 2px solid;
    padding: 6px;
    padding-top: 8px;
    width: 65%;
    background-color: #046772;
  }
  .mainNavHidden{
    opacity: 0;
    display: flex;
    flex-wrap: wrap;
    height: 0px;
    transition: 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.175);
  }
  .mainNavHidden div {
    width: 100%;
    z-index: -10;
    pointer-events: none;
  }
  .loginHeader{
    box-shadow: rgba(153, 153, 155, 0.25) 0px 30px 60px -12px inset, rgba(110, 108, 108, 0.3) 0px 18px 36px -18px inset;

    border-bottom: solid 4px var(--main-bg-color);
    border-top-left-radius: 10px;
  }
  .signupHeader{
    box-shadow: rgba(153, 153, 155, 0.25) 0px 30px 60px -12px inset, rgba(110, 108, 108, 0.3) 0px 18px 36px -18px inset;
    border-bottom: solid 4px var(--main-bg-color);
    border-top-right-radius: 10px;
  }
}




.navOpen div:hover{
  background-color: #06b6d4;
  transition: 0.85s;
}
.navOpen div::after{
  background-color: #0e7490;
  font-size: medium;
  transition: 0.85s;
}
.navClosed{
  background-color: #0e7490; 
  color: white;
  font-weight: 600;
  height: 0px;
  transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.175);
}

.guideBookNav{
  font-size: 17px;
}
.guideBookNav article{
  /* border-right: var(--second-color) 2px solid; */
  padding: 0px 35px 0px 25px;
  padding-top: 0.5rem;
}

.guideBookNav article h3{
  margin-bottom: 5px;
}
.guideBookNav article div a{
  cursor: pointer;
  display: block!important;
  width: max-content;
}
.guideBookNav section{
  padding: 0px 35px 0px 25px;
  padding-top: 0.5rem;
}
.guideBookNav section h3{
  margin-bottom: 5px;
}
.guideBookNav section div a{
  cursor: pointer;
  display: block!important;
  width: max-content;
}


/* login CSS  */
.toggleContainer {
  transform: rotateY(180deg);
  transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.175);
}
.toggleContainer2 {
  transform: rotateY(360deg);
  transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.175);
}
.toggleChildren {
  transform: rotateY(180deg);
  transition: .1s cubic-bezier(0.175, 0.885, 0.32, 1.175);
}


/* .toggleElement2 {
  position: absolute;
  left: 50%;
  width: 50%;
  height: 100%;
  border-radius: 15px;
  transition: 1s cubic-bezier(0.175, 0.885, 0.32, 1.175);
} */

.toggleBtn1:focus {
  transition: 0.8s;
  transform: rotateY(0deg);
}

.toggleBtn2:focus {
  transition: 0.8s;
  transform: rotateY(360deg);
}

/* guide mobile navigation here to line 150*/
.navDefault{
  background-color: rgb(15 118 110);
}
.navForest {
  background-color: rgb(15 118 110);
}
.navOcean{
  background-color: rgb(48, 169, 180);
}
.navCity{
  background-color: rgb(56, 56, 56);
}

.navDefaultText{
  color: rgb(15 118 110);
}
.navForestText {
  color: rgb(15 118 110);
}
.navOceanText{
  color: rgb(48, 169, 180);
}
.navCityText{
  color: rgb(56, 56, 56);
}

.infoNav{
  height: 5rem;
}

.navigation {
  position: sticky;
  bottom: 0;
  z-index: 100;
  position: relative;
  width: full;
  height: 5rem;
  /* height: 12%; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  color: white;
}

.navigation ul{
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.navigation ul li{
  position: relative;
  list-style: none;
  width: 330%;
  height: 100%;
  z-index: 1;
}

.navigation ul li button{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  font-weight: 500;
}

.navigation ul li button .icon{
  position: relative;
  display: block;
  line-height: 55px;
  font-size: 1.5em;
  text-align: center;
  transition: 0.5s;
  color: var(black);
}

.navigation ul li.active button .icon{
  transform: translateY(-10px);
}


.navigation ul li button .text{
  position: absolute;
  color: var(black);
  font-weight: 700;
  font-size: 0.8em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(45px);
}

.navigation ul li.active button .text{
  opacity: 1;
  transform: translateY(20px);
}

.navBreak {
  background-color: white;
}

.indicator{
  width: 25%;
  height: 100%;
  background-color: rgb(17 94 89);
  /* border: 6px solid var(--clr); */
  transition: 0.5s;
}


.navigation ul li:nth-child(1).active ~ .indicator{
  left: 0px;
}

.navigation ul li:nth-child(3).active ~ .indicator{
  left: 25%;
} 

.navigation ul li:nth-child(5).active ~ .indicator{
  left: 50%;
}
.navigation ul li:nth-child(7).active ~ .indicator{
  left: 75%;
} 

/* GUIDE DEFAULT CSS */
.guideHomebg{
  
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  z-index: -10;
}

.guideHomeFront{
  display: block;
  z-index: 10;
}

.guideHome{
  position: relative;
  opacity: 1;
  color: black;
}

/* .guideInfo{
  color: rgb(15 118 110);
  background-color: rgb(215, 215, 215);
} */

/* GUIDE CITY CSS */
.guideHomeCity{
  position: relative;
  opacity: 1;
  background-color: rgb(56, 56, 56);
  color: white;
}

.guideInfoCity{
  color: rgb(56, 56, 56);
  background-color: rgb(215, 215, 215);
}

.categoryDivCity{
  background-color: rgb(56, 56, 56);
}

/* GUIDE Forest CSS */

.guideHomeForest{
  position: relative;
  opacity: 1;
  color: white;
  background-color: rgb(15 118 110);
}

.guideInfoForest{
  color: rgb(15 118 110);
  background-color: rgb(215, 215, 215);
}

.categoryDivForest{
  background-color: rgb(15 118 110);
}

/* GUIDE Ocean CSS */
.guideHomeOcean{
  position: relative;
  opacity: 1;
  color: white;
  background-color: rgb(48, 169, 180);
}

.guideInfoOcean{
  color: rgb(48, 169, 180);
  background-color: rgb(215, 215, 215);
}
themeTextClass{
}

.categoryDivOcean{
  background-color: rgb(48, 169, 180);
}

/* input css */
input[type=text] {
  word-wrap: break-word;
  word-break: break-all;
}

/* MAP CSS */

.guideMap {
  height: 100%;
}

.centerMapButtonIcon {
  transform: rotate(-45deg);
}


.selectedSub {
  border: solid 8px var(--second-color);
  border-radius: 11px;
  padding: 0px;
}

.inputText {
  color: rgb(156 163 175);
}
.guideShadow{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.rotate{
  transform: rotateY(180deg);
}

.navUnderline{
  border-bottom: 4px solid var(--second-color);
}
.navUnderline2{
  border-bottom: 4px solid var(--second-color);
}
.dashboardNavBorder{
  border-bottom: 8px solid var(--second-color);
}

.blueGradientBG{
  background-image: linear-gradient(rgb(59, 66, 74), #102440, #102440);
  box-shadow: 0 10px 20px rgba(16, 36, 64, .1),0 3px 6px rgba(16, 36, 64, .05);
}
.blueGradientBG:hover {
  box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
  opacity: .90;
}
.hoverShadow:hover{
  box-shadow: 0 10px 20px rgba(16, 36, 64, .1),0 3px 6px rgba(16, 36, 64, .05);
}
/* CSS */
.dashboardBtn {
  align-items: center;
  background-color: initial;
  background-image: linear-gradient(rgb(73, 82, 93), #102440, #102440);
  /* background-image: linear-gradient(rgb(73, 82, 93), #102440); */
  border-radius: 8px;
  border-width: 0;
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, .1),0 3px 6px rgba(0, 0, 0, .05); */
  box-shadow: 0 10px 20px rgba(16, 36, 64, .1),0 3px 6px rgba(16, 36, 64, .05);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  font-size: 18px;
  height: 52px;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  transform: translate3d(0, 0, 0);
  transition: all 150ms;
  vertical-align: baseline;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.dashboardBtn:hover {
  box-shadow: rgba(0, 1, 0, .2) 0 2px 8px;
  opacity: .85;
}

.dashboardBtn:active {
  outline: 0;
}

.dashboardBtn:focus {
  box-shadow: rgba(0, 0, 0, .5) 0 0 0 3px;
}

@media (max-width: 420px) {
  .bdashboardBtn {
    height: 48px;
  }
}


.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: white;
}

.hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: var(--second-color);
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom center;
} 

.homeText {
  color: var(--main-bg-color);
}

.home-hover-underline-animation {
  display: inline-block;
  position: relative;
  color: black;
}

.home-hover-underline-animation:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: var(--second-color);
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}

.home-hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom center;
} 

.hover-underline-animation2 {
  display: inline-block;
  position: relative;
  color: white;
}

.hover-underline-animation2:after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: var(--main-bg-color);
  transform-origin: bottom center;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation2:hover:after {
  transform: scaleX(1);
  transform-origin: bottom center;
} 

.circle{
  border: 4px solid var(--second-color);
}

.curved{
  background: var(--main-bg-color);
  color: white;
  text-align: center;
}

.curved h1 {
  font-size: 6rem;
}

.curveSvg {
  margin-bottom: -15rem;
}
.anotherSvg{
  margin-bottom: -27rem;
}

.exampleSvg {
  margin-bottom: -22rem;
}

.mainHeader{
  margin-bottom: -8rem;
}

.exampleHeader{
  margin-bottom: -6rem;
}

.imageCover {
  opacity: 0.6;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.objectCover {
  object-fit: cover;
}

#GUIDE_PREVIEW{
  height: 400px;
  width: 250px;
}
#GUIDE_NAV{
  width: 250px;
}

.fullOpacity{
  position: absolute;
  opacity: 100 !important;
  z-index: 100 !important;
}

.linkShadow{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.shareMenu {
  border-radius: 10px;
  position: sticky;
  bottom: 84%;
  right: 5%;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  width: 58.5%; 
  height: 260px;
  z-index: 20;
  background-color: white;
  position: relative;
  opacity: 100;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  transition: .5s ease;
  margin-bottom: -40rem;
}

.editNavbg{
  background-color: var(--second-color);
}
@media only screen and (max-width: 650px) {
  .shareMenu {
    position: sticky;
    top: 35%;
    width: 90%;
    margin: auto;
  }
  .editNavbg{
    background-color: var(--main-bg-color);
  }
  .whiteText{
    color: white;
  }
  .curveSvg{
    margin-bottom: -4rem;
  }
  .mobileCurve{
    margin-bottom: -4rem;
  }
}


.hiddenShareMenu {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  background-color: var(--second-color);
  display: none;
  opacity: 0;
  width: 100%;
  height: 0px;
  transition: .5s ease;
}

.subBorder {
  width: 300px;
  height: 80px;
  border: double 4px transparent;
  border-radius: 15px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #92e5ee,var(--main-bg-color));
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.rotateButtonDown{
  transform: rotate(0deg);
  color: white;
  transition: all .25s linear;
}
.rotateButtonUp{
  transform: rotate(180deg);
  color: white;
  transition: all .25s linear;
}

.categorySectionClosed{
  height: 55px;
  padding-bottom: 0px;
  margin-bottom: 20px;
  transition: all .25s linear;
  margin-bottom: 20px!important;
}
.categorySectionOpen{
  height: max-content;
  transition: all .25s linear;
}
.categorySectionClosed article{
  transform: rotateX(90deg);
  height: 0px;
  transform-origin: top;
  transition: all .25s linear;
  margin-bottom: -40px!important;
  display: none;
}
.categorySectionOpen article{
  display: block;
  transform: rotateX(0deg);
  /* height: 140px; */
  transform-origin: top;
  transition: all .25s linear;
  padding-bottom: 8px;
  margin-bottom: 20px;
}
.categorySectionClosed article button{
  transform: rotateX(90deg);
  transform-origin: top;
  transition: all .1s linear;
}
.categorySectionOpen article button{
  transform: rotateX(0deg);
  transform-origin: top;
  transition: all .4s linear;
}


.rotateSubButtonDown div{
  display: inline-block;
  transform: rotate(0deg);
  transition: all .25s linear;
}
.rotateSubButtonUp div{
  display: inline-block;
  transform: rotate(180deg);
  transition: all .25s linear;
}

.subCategory{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
.subCategoryClosed article{
  transform: rotateX(90deg);
  transform-origin: top;
  height: 0px;
  opacity: 0;
  transition: all .1s linear;
}
.subCategoryOpen article{
  transform: rotateX(0deg);
  opacity: 100;
  padding: 5px;
  height: max-content;
  transform-origin: top;
  transition: all .4s linear;
}
.subCategoryMenuOpen nav{
  float: right;
  position: relative;
  left: 10px;
  margin-top: .5rem;
  margin-bottom: -5%;
  float: right;
  z-index: 10;
  transform: rotateX(0deg);
  transform-origin: top;
  transition: all .25s linear;

}
.subCategoryMenuClosed nav{
  float: right;
  position: relative;
  left: 10px;
  margin-top: .5rem;
  margin-bottom: -5%;
  float: right;
  z-index: 10;
  transform: rotateX(90deg);
  transform-origin: top;
  transition: all .25s linear;
}

.categoryNav{
  position: relative;
  margin-bottom: -5%;
  float: right;
  z-index: 10;
  left: 100%;
}
.categoryNavClosed{
  transform: rotateX(90deg);
  transform-origin: top;
  transition: all .25s linear;
  margin-left: auto;
}
.categoryNavOpen{
  position: relative;
  transform: rotateX(0deg);
  z-index: 100;
  transform-origin: top;
  transition: all .25s linear;
  margin-left: auto;
}

.categoryNavTransition:hover{
  background-color: #e7dfc6;
  transition: all .5s linear;
  
}
.categoryNavTransition{
  background-color: white;
  transition: all .5s linear;
  font-weight: 600;
  /* color: var(--main-bg-color); */
}

/* select icon for new section css */
select#icon option[value="wifi"] {
  background-image: url(https://d1nhio0ox7pgb.cloudfront.net/_img/g_collection_png/standard/512x512/wifi.png);
}

.linkIcons{
  border: 3px solid var(--second-color);
}

/* Save to home text bubble pop up css */
.speech-bubble {
	position: relative;
  z-index: 200;
	background: white;
	border-radius: .4em;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 21px solid transparent;
	border-top-color: white;
	border-bottom: 1px solid black;
	margin-left: -21px;
	margin-bottom: -20px;
}
.noMargin {
  margin-bottom: 2rem;
}
.bigZ{
  z-index: 300;
}
.navPreview {
  position: relative;
  right: 10px;
  top: 74px
}
.previewHeight{
  height: 500px;
}
.previewRounded{
  border-radius: 40px;
}
.previewRoundedTop{
  border-top-left-radius:  40px;
  border-top-right-radius:  40px;
}
.topright {
  position: absolute;
  right: 25%;
}
@media screen and (max-width: 680px) {
  .topright{
    position: relative;
    top: 540px;
    left: 0%
  }
}

.previewNav{
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}

.testBg{
  background-color: #3A3B3c;
  height: 70px;
}





/* TOOL TIP  */
.tooltip {
  display:inline-block;
  position:relative;
  text-align:left;
}

.tooltip .top {
  width: max-content;
  min-width:100px; 
  max-width:400px;
  top:-20px;
  left:120%;
  transform:translate(-85%, -100%);
  padding:10px 20px;
  color:#444444;
  background-color:#ffffff;
  font-weight:normal;
  font-size:13px;
  border-radius:8px;
  position:absolute;
  z-index:99999999;
  box-sizing:border-box;
  border:1px solid #777777;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
  visibility:hidden; 
  opacity:0; 
  transition:opacity 0.8s;
}

.tooltip:hover .top {
  visibility:visible; opacity:1;
}

.tooltip .top i {
  position:absolute;
  top:100%;
  left:79%;
  margin-left:-12px;
  width:24px;
  height:12px;
  overflow:hidden;
}

.tooltip .top p {
  line-height: 1rem; 
}

.tooltip .top i::after {
  content:'';
  position:absolute;
  width:12px;
  height:12px;
  left:50%;
  transform:translate(-50%,-50%) rotate(45deg);
  background-color:#EEEEEE;
  border:1px solid #777777;
  box-shadow:0 1px 8px rgba(0,0,0,0.5);
}

.loginBg{
  margin-left: -440px;
  width: 80%;
}
/* CSS FOR SCREENS BETWQEEN 700-1500 */

@media (max-width: 1500px) and (min-width: 765px) {
  .homeDefault{
    /* padding-top: 50px; */
    /* position: relative;
    top:40px; */
  }
  .homeDefault div svg {
    margin-bottom: 5rem;
  }
  .homeDefault section img {
    max-width:490px;
    max-height:270px;
    width: auto;
    height: auto;
    margin-top: 50px;
  }
  .homeDefault section{
    padding-bottom: 100px;
  }
  .homeDefault article{
    display: none;
  }
  #homeTeaser{
    margin-top: 50px;
  }
  .guideBookIndex{
    margin-bottom: 7rem;
  }
  .guidePageHeader{
    margin-bottom: 6rem;
  }
  .makeGuideSlideShow{
    margin-bottom: 1rem;
  }
  .bottomGuidePage{
    margin-top: 5rem;
  }
  .loginBg{
    margin-left: -250px;
    width: 100%;
  }
}
@media (max-width: 1090px) and (min-width: 767px){
  .loginBg{
    margin-left: -20px;
    width: 100%;
  }
}
@media (max-width: 767px) and (min-width: 650px){
  .homeDefault section{
    padding-top: 150px;
  }
}


.error{
  animation: shake .3s ease-in-out 0s 1.5;
  box-shadow: 0 0 0.25em red;
}

@keyframes shake {
  0% { margin-left: 0rem; }
  25% { margin-left: 0.25rem; }
  75% { margin-left: -0.25rem; }
  100% { margin-left: 0rem; }
}


	.text-shadow{
    text-shadow: 0px 0px 10px rgb(0, 0, 0);
  }

.notAllowed {
  cursor: not-allowed;
}

.reviewScroll::-webkit-scrollbar{
  width: 0;
}
.reviewScroll{
  -webkit-overflow-scrolling: touch;
}

/* Dragging CSS */
.draggable.dragging{
  opacity: .5;
}